
  import {computed, defineComponent} from "vue";
  import {useRouter} from "vue-router";
  import {useStore} from "vuex";
  import useSessions from "../composables/useSessions";
  import useTeams from "../composables/useTeams";
  import {ElMessage} from "element-plus/es";

  export default defineComponent({
    name: "HeaderNav",
    setup(props) {
      const router = useRouter();
      const store = useStore();
      const {deleteSession} = useSessions();
      const {switchTeam} = useTeams();

      const handleDropdownCommand = (command: string) => {
        switch (command) {
          case "settings":
            router.push("/settings")
            break
          case "change-password":
            router.push("/change-password")
            break
          case 'logout':
            logout()
            break
          default:
            return
        }
      }

      const logout = async () => {
        await deleteSession();
        ElMessage({
          showClose: true,
          message: '退出成功',
          type: 'success',
        });
        setTimeout(function () {
          router.replace('/');
        }, 500);
      }

      return {
        switchTeam,
        handleDropdownCommand,
        deleteSession,
        authenticated: computed(() => store.state.user.authenticated),
        nickname: computed(() => store.state.user?.principal?.user?.nickname),
        currentTeam: computed(() => store.state.currentTeam),
        currentTeamName: computed(() => {
          const currentTeam = store.state.currentTeam
          if (currentTeam && currentTeam.name) {
            return currentTeam.name.length <= 25 ? currentTeam.name : currentTeam.name.substr(0, 25) + '...';
          } else {
            return null;
          }
        })
      }
    },
  });
