export const HOST = '/api';

//sessions
export const LOGOUT = `${HOST}/logout`;
export const USER = `${HOST}/user`;

//file tree
export const LIST_PARENT_TREE_BY_CURRENT_NOTE_ID = `${HOST}/fileTree/listParentTreeByCurrentNoteId`;
export const TREE_OF_ROOT = `${HOST}/fileTree/getTreeOfRoot`;

//folders
export const FOLDERS = `${HOST}/folders`;
export const LIST_RECURSIVE_PARENTS = `${HOST}/folders/listRecursiveParents`;
export const MOVE_FOLDER = `${HOST}/folders/move`;

//notes
export const NOTES = `${HOST}/notes`;
export const UPDATE_DEFAULT_RENDERING = `${NOTES}/updateDefaultRendering`;
export const NOTE_UPLOAD_IMAGE = `${NOTES}/uploadImage`;
export const NOTE_IMAGE = `${NOTES}/image`;
export const RESTORE = `${NOTES}/restore`;
export const NOTE_RENAME = `${NOTES}/rename`;
export const NOTE_DUPLICATE = `${NOTES}/duplicate`;
export const NOTE_SHARE = `${NOTES}/share`;
export const MOVE_NOTE = `${HOST}/notes/move`;

//shared notes
export const SHARED_NOTES = `${HOST}/sharedNotes`;
export const SHARED_NOTE_IMAGE = `${SHARED_NOTES}/image`;

//noteHistories
export const NOTE_HISTORIES = `${HOST}/noteHistories`;
export const NOTE_HISTORY_UNIFIED_DIFF = `${HOST}/noteHistories/getNoteHistoryUnifiedDiff`;

//teams
export const TEAMS = `${HOST}/teams`;

//users
export const USERS = `${HOST}/users`;
export const CHANGE_PASSWORD = `${HOST}/users/changePassword`;

//trash
export const TRASH = `${HOST}/trash`;
export const RECALL = `${TRASH}/recall`;
